"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Description = exports.Container = void 0;
var polished_1 = require("polished");
var styled_components_1 = __importStar(require("styled-components"));
exports.Container = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  /* height: 100%; */\n\n  display: flex;\n  align-items: center;\n  gap: ", ";\n\n  flex-direction: ", ";\n\n  /* overflow: hidden; */\n  position: relative;\n"], ["\n  width: 100%;\n  /* height: 100%; */\n\n  display: flex;\n  align-items: center;\n  gap: ", ";\n\n  flex-direction: ", ";\n\n  /* overflow: hidden; */\n  position: relative;\n"])), (0, polished_1.rem)(40), function (_a) {
    var flexDirection = _a.flexDirection;
    return flexDirection;
});
exports.Description = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  ", "\n\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n\n  span {\n    font-size: ", ";\n\n    display: flex;\n    align-items: center;\n    gap: ", ";\n\n    color: ", ";\n  }\n\n  h1 {\n    font-size: ", ";\n    font-weight: normal;\n    color: ", ";\n    line-height: ", ";\n  }\n"], ["\n  width: 100%;\n  ", "\n\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n\n  span {\n    font-size: ", ";\n\n    display: flex;\n    align-items: center;\n    gap: ", ";\n\n    color: ", ";\n  }\n\n  h1 {\n    font-size: ", ";\n    font-weight: normal;\n    color: ", ";\n    line-height: ", ";\n  }\n"])), function (_a) {
    var fullWidth = _a.fullWidth, maxWidthDescription = _a.maxWidthDescription;
    return !fullWidth && (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      min-width: ", ";\n      max-width: ", ";\n    "], ["\n      min-width: ", ";\n      max-width: ", ";\n    "])), (0, polished_1.rem)(100), (0, polished_1.rem)(maxWidthDescription));
}, (0, polished_1.rem)(25), (0, polished_1.rem)(16), (0, polished_1.rem)(10), function (_a) {
    var theme = _a.theme, colorTag = _a.colorTag;
    switch (colorTag) {
        case 'purple':
            return theme.colors.purple[500];
        case 'cyan':
            return theme.colors.cyan[500];
        case 'blue':
            return theme.colors.blue[900];
        default:
            return theme.colors.purple[500];
    }
}, (0, polished_1.rem)(44), function (_a) {
    var theme = _a.theme;
    return theme.colors.blue[900];
}, (0, polished_1.rem)(50));
var templateObject_1, templateObject_2, templateObject_3;
