"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Container = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var polished_1 = require("polished");
exports.Container = styled_components_1.default.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 1rem 2rem;\n  border: none;\n  border-radius: ", ";\n\n  font-size: ", ";\n  font-weight: bold;\n  cursor: pointer;\n  transition: all 0.2s;\n\n  &:hover:not(:disabled) {\n    ", "\n    \n  }\n\n  color: #fff;\n\n  ", ";\n\n  ", "\n\n  ", "\n\n  &:disabled {\n    cursor: not-allowed;\n    opacity: 0.5;\n  }\n\n  @media (max-width: 650px) and (orientation: portrait) {\n    font-size: 160% !important; \n  }\n"], ["\n  padding: 1rem 2rem;\n  border: none;\n  border-radius: ", ";\n\n  font-size: ", ";\n  font-weight: bold;\n  cursor: pointer;\n  transition: all 0.2s;\n\n  &:hover:not(:disabled) {\n    ", "\n    \n  }\n\n  color: #fff;\n\n  ", ";\n\n  ", "\n\n  ", "\n\n  &:disabled {\n    cursor: not-allowed;\n    opacity: 0.5;\n  }\n\n  @media (max-width: 650px) and (orientation: portrait) {\n    font-size: 160% !important; \n  }\n"])), (0, polished_1.rem)(20), (0, polished_1.rem)(18), function (_a) {
    var theme = _a.theme, color = _a.color;
    switch (color) {
        case 'purple':
            return (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            background-color: #003750;\n          "], ["\n            background-color: #003750;\n          "])));
        default:
            return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            /* filter: brightness(0.8); */\n          "], ["\n            /* filter: brightness(0.8); */\n          "])));
    }
}, function (_a) {
    var theme = _a.theme, color = _a.color;
    switch (color) {
        case 'primary':
            return (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          \n          background: ", ";\n        "], ["\n          \n          background: ", ";\n        "])), theme.colors.primary);
        case 'purple':
            return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          background: ", ";\n        "], ["\n          background: ", ";\n        "])), theme.colors.purple[500]);
        case 'white':
            return (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          color: ", ";\n          background: ", ";\n        "], ["\n          color: ", ";\n          background: ", ";\n        "])), theme.colors.blue[900], theme.colors.white);
        default:
            return theme.colors.primary;
    }
}, function (_a) {
    var theme = _a.theme, variant = _a.variant, color = _a.color;
    if (variant == 'outline' && color == 'gray') {
        return (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        background-color:  transparent;\n        border: ", " solid ", ";\n        color: ", ";\n\n        &:hover:not(:disabled){\n          background-color:  ", ";\n          color: white;\n        }\n      "], ["\n        background-color:  transparent;\n        border: ", " solid ", ";\n        color: ", ";\n\n        &:hover:not(:disabled){\n          background-color:  ", ";\n          color: white;\n        }\n      "])), (0, polished_1.rem)(2), theme.colors.gray[400], theme.colors.gray[400], theme.colors.gray[400]);
    }
    if (variant == 'outline' && color == 'cyan') {
        return (0, styled_components_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        background-color:  transparent;\n        border: ", " solid ", ";\n        color: ", ";\n\n        &:hover:not(:disabled){\n          background-color:  ", ";\n          color: ", ";\n        }\n      "], ["\n        background-color:  transparent;\n        border: ", " solid ", ";\n        color: ", ";\n\n        &:hover:not(:disabled){\n          background-color:  ", ";\n          color: ", ";\n        }\n      "])), (0, polished_1.rem)(2), theme.colors.cyan[500], theme.colors.cyan[500], theme.colors.cyan[500], theme.colors.gray[400]);
    }
    if (variant == 'outline') {
        return (0, styled_components_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n          background-color:  transparent;\n          border: ", " solid ", ";\n          &:hover:not(:disabled){\n            background-color:  ", ";\n            border-color:  ", ";\n            color: white;\n          }\n        "], ["\n          background-color:  transparent;\n          border: ", " solid ", ";\n          &:hover:not(:disabled){\n            background-color:  ", ";\n            border-color:  ", ";\n            color: white;\n          }\n        "])), (0, polished_1.rem)(2), theme.colors.white, theme.colors.gray[400], theme.colors.gray[400]);
    }
}, function (_a) {
    var fullWidth = _a.fullWidth;
    return fullWidth && (0, styled_components_1.css)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      width: 100%;\n    "], ["\n      width: 100%;\n    "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
