"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconSecurity = exports.IconPaymentMethod = exports.IconFastClock = exports.IconEasyUse = exports.IconClose = exports.IconMenu = exports.IconSmallCardPos = exports.IconClock = exports.IconCalculator = exports.IconSmallArchBlue = exports.IconSmallArchPurple = exports.IconSmallArchCyan = exports.IconTrendUp = exports.IconHealth = exports.IconCard = exports.ChevronRight = exports.CheckBoxMoreIcon = exports.CheckBoxLessIcon = exports.EmailIcon = void 0;
var linear_sms_notification_svg_1 = require("../static/svg/linear-sms-notification.svg");
Object.defineProperty(exports, "EmailIcon", { enumerable: true, get: function () { return linear_sms_notification_svg_1.ReactComponent; } });
var linear_checkbox_show_less_svg_1 = require("../static/svg/linear-checkbox-show-less.svg");
Object.defineProperty(exports, "CheckBoxLessIcon", { enumerable: true, get: function () { return linear_checkbox_show_less_svg_1.ReactComponent; } });
var linear_checkbox_show_more_svg_1 = require("../static/svg/linear-checkbox-show-more.svg");
Object.defineProperty(exports, "CheckBoxMoreIcon", { enumerable: true, get: function () { return linear_checkbox_show_more_svg_1.ReactComponent; } });
var chevron_right_svg_1 = require("../static/svg/chevron-right.svg");
Object.defineProperty(exports, "ChevronRight", { enumerable: true, get: function () { return chevron_right_svg_1.ReactComponent; } });
var icon_card_svg_1 = require("../static/svg/icon-card.svg");
Object.defineProperty(exports, "IconCard", { enumerable: true, get: function () { return icon_card_svg_1.ReactComponent; } });
var icon_health_svg_1 = require("../static/svg/icon-health.svg");
Object.defineProperty(exports, "IconHealth", { enumerable: true, get: function () { return icon_health_svg_1.ReactComponent; } });
var icon_trend_up_svg_1 = require("../static/svg/icon-trend-up.svg");
Object.defineProperty(exports, "IconTrendUp", { enumerable: true, get: function () { return icon_trend_up_svg_1.ReactComponent; } });
var small_arch_cyan_svg_1 = require("../static/svg/small-arch-cyan.svg");
Object.defineProperty(exports, "IconSmallArchCyan", { enumerable: true, get: function () { return small_arch_cyan_svg_1.ReactComponent; } });
var small_arch_purple_svg_1 = require("../static/svg/small-arch-purple.svg");
Object.defineProperty(exports, "IconSmallArchPurple", { enumerable: true, get: function () { return small_arch_purple_svg_1.ReactComponent; } });
var small_arch_blue_svg_1 = require("../static/svg/small-arch-blue.svg");
Object.defineProperty(exports, "IconSmallArchBlue", { enumerable: true, get: function () { return small_arch_blue_svg_1.ReactComponent; } });
var calculator_svg_1 = require("../static/svg/calculator.svg");
Object.defineProperty(exports, "IconCalculator", { enumerable: true, get: function () { return calculator_svg_1.ReactComponent; } });
var clock_svg_1 = require("../static/svg/clock.svg");
Object.defineProperty(exports, "IconClock", { enumerable: true, get: function () { return clock_svg_1.ReactComponent; } });
var icon_card_pos_svg_1 = require("../static/svg/icon-card-pos.svg");
Object.defineProperty(exports, "IconSmallCardPos", { enumerable: true, get: function () { return icon_card_pos_svg_1.ReactComponent; } });
var menu_svg_1 = require("../static/svg/menu.svg");
Object.defineProperty(exports, "IconMenu", { enumerable: true, get: function () { return menu_svg_1.ReactComponent; } });
var close_svg_1 = require("../static/svg/close.svg");
Object.defineProperty(exports, "IconClose", { enumerable: true, get: function () { return close_svg_1.ReactComponent; } });
var easy_use_svg_1 = require("../static/svg/easy-use.svg");
Object.defineProperty(exports, "IconEasyUse", { enumerable: true, get: function () { return easy_use_svg_1.ReactComponent; } });
var fast_svg_1 = require("../static/svg/fast.svg");
Object.defineProperty(exports, "IconFastClock", { enumerable: true, get: function () { return fast_svg_1.ReactComponent; } });
var payment_method_svg_1 = require("../static/svg/payment-method.svg");
Object.defineProperty(exports, "IconPaymentMethod", { enumerable: true, get: function () { return payment_method_svg_1.ReactComponent; } });
var security_svg_1 = require("../static/svg/security.svg");
Object.defineProperty(exports, "IconSecurity", { enumerable: true, get: function () { return security_svg_1.ReactComponent; } });
