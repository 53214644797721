// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fqdk_C7fObLrQ4Z4Od5T{width:100%;display:flex;flex-direction:column;align-items:flex-start;font-weight:500;font-size:1rem;color:#1c3d53;gap:0.75rem}.MGbhG1pRuSn9mdtJYJvM{width:100%;height:9rem;border:1px solid #afafaf2c;border-radius:0.63rem;font-size:1rem;font-family:Poppins;resize:vertical;padding:1rem}.MGbhG1pRuSn9mdtJYJvM::placeholder{color:#afafaf}
`, "",{"version":3,"sources":["webpack://./src/components/Form/Textarea/styles.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA,sBACE,UAAW,CACX,YAAa,CACb,qBAAsB,CAEtB,sBAAuB,CACvB,eAAgB,CAChB,cAAe,CACf,aCVqB,CDWrB,WAAY,CACb,sBAGC,UAAW,CACX,WAAY,CACZ,0BAA2B,CAC3B,qBAAsB,CACtB,cAAe,CACf,mBAAoB,CAEpB,eAAgB,CAChB,YAAa,CATf,mCAYI,aCpBc","sourcesContent":["@import '../../../styles/variables.scss';\n\n.label {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n\n  align-items: flex-start;\n  font-weight: 500;\n  font-size: 1rem;\n  color: $primary-color;\n  gap: 0.75rem;\n}\n\n.input {\n  width: 100%;\n  height: 9rem;\n  border: 1px solid #afafaf2c;\n  border-radius: 0.63rem;\n  font-size: 1rem;\n  font-family: Poppins;\n\n  resize: vertical;\n  padding: 1rem;\n\n  &::placeholder {\n    color: $gray-400;\n  }\n}\n","$primary-color: #1c3d53;\n$background-color: #f4f7f9;\n$black-800: #010202;\n$black-700: #0b1922;\n$gray-500: #5e5e5d;\n$gray-450: #707070;\n$gray-400: #afafaf;\n$purple: #6d3aff;\n$blue: #3278d0;\n$button-gradient: transparent linear-gradient(119deg, $purple 0%, $blue 100%) 0%\n  0% no-repeat padding-box;\n\n$background: #F2F4F8;\n$primary: #1C3D53;\n\n$blue-900: #0A2A58;\n$purple-500: #656DFD;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `fqdk_C7fObLrQ4Z4Od5T`,
	"input": `MGbhG1pRuSn9mdtJYJvM`
};
export default ___CSS_LOADER_EXPORT___;
