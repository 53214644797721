"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var styles_1 = require("./styles");
function Button(_a) {
    var children = _a.children, _b = _a.color, color = _b === void 0 ? 'primary' : _b, _c = _a.fullWidth, fullWidth = _c === void 0 ? false : _c, _d = _a.variant, variant = _d === void 0 ? 'solid' : _d, rest = __rest(_a, ["children", "color", "fullWidth", "variant"]);
    return ((0, jsx_runtime_1.jsx)(styles_1.Container, __assign({ color: color, fullWidth: fullWidth, type: "button", variant: variant }, rest, { children: children })));
}
exports.default = (0, react_1.memo)(Button);
