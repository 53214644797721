"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuFloating = exports.menu = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var styles_1 = require("./styles");
exports.menu = [
    { label: 'Inicio', path: '/' },
    { label: 'Nosotros', path: '/about-us' },
    {
        label: 'Soluciones',
        path: '/solutions'
        // group: [
        //   {
        //     label: 'Para ti',
        //     menu: [
        //       { label: 'Pasarela de pagos', path: '/payment-gateway' },
        //       { label: 'Tarjeta prepagada', path: '/prepaid-card' },
        //     ],
        //   },
        //   {
        //     label: 'Proximamente',
        //     menu: [
        //       { label: 'Pagos QR' },
        //       { label: 'Desarrollo de aplicativos' },
        //       { label: 'Hosting and VPSs' },
        //       { label: 'Identidad digital Global' },
        //       { label: 'Software factory' },
        //     ],
        //   },
        // ],
    },
    { label: 'Contáctanos', path: '/contacts' },
    { label: 'Preguntas frecuentes', path: '/faq' },
];
function MenuFloating(_a) {
    return ((0, jsx_runtime_1.jsxs)(styles_1.Container, { children: [(0, jsx_runtime_1.jsx)("div", {}), (0, jsx_runtime_1.jsx)(styles_1.ContainerNav, { children: exports.menu.map(function (item) {
                    var _a, _b, _c;
                    if (!!item.path) {
                        return ((0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, __assign({ to: item.path }, { children: item.label }), item.label));
                    }
                    return ((0, jsx_runtime_1.jsxs)("a", __assign({ className: "has-group" }, { children: [item.label, ' ', ((_b = (_a = item === null || item === void 0 ? void 0 : item.group) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0 && ((0, jsx_runtime_1.jsx)(styles_1.Group, __assign({ className: "group-items" }, { children: (_c = item.group) === null || _c === void 0 ? void 0 : _c.map(function (i) { return ((0, jsx_runtime_1.jsxs)(styles_1.GroupItem, { children: [(0, jsx_runtime_1.jsx)("p", { children: i.label }), (0, jsx_runtime_1.jsx)("ul", { children: i.menu.map(function (m) { return ((0, jsx_runtime_1.jsx)("li", { children: !!(m === null || m === void 0 ? void 0 : m.path) ? ((0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, __assign({ to: m.path }, { children: m.label }))) : (m.label) }, m.label)); }) })] })); }) })))] }), item.label));
                }) }), (0, jsx_runtime_1.jsx)("div", {})] }));
}
exports.MenuFloating = MenuFloating;
