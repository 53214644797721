"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    theme: 'light',
    colors: {
        background: '#F2F4F8',
        primary: '#1C3D53',
        white: '#FFFFFF',
        blue: {
            900: '#0A2A58',
        },
        purple: {
            500: '#656DFD',
        },
        gray: {
            400: '#233C52',
        },
        cyan: {
            500: '#00BDF2',
        },
    },
};
