"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupItem = exports.Group = exports.MenuContainer = exports.ButtonMenu = exports.HeaderContainer = exports.ContainerHeader = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var polished_1 = require("polished");
exports.ContainerHeader = styled_components_1.default.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n\n  width: 100%;\n  height: ", ";\n\n  ", "\n  \n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  @media (max-width: 650px) and (orientation: portrait) {\n    height: ", ";\n    background-color: white;\n    z-index: 3;\n    border-radius: 0 0 ", " ", ";\n\n    > div {\n      height: 100%;\n    }\n  }\n"], ["\n  position: absolute;\n  top: 0;\n\n  width: 100%;\n  height: ", ";\n\n  ", "\n  \n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  @media (max-width: 650px) and (orientation: portrait) {\n    height: ", ";\n    background-color: white;\n    z-index: 3;\n    border-radius: 0 0 ", " ", ";\n\n    > div {\n      height: 100%;\n    }\n  }\n"])), (0, polished_1.rem)(155), function (_a) {
    var withBackground = _a.withBackground;
    return withBackground && (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: white;\n  "], ["\n    background-color: white;\n  "])));
}, (0, polished_1.rem)(95), (0, polished_1.rem)(20), (0, polished_1.rem)(20));
exports.HeaderContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  /* max-width: ", "; */\n  max-width: ", ";\n  /* margin: ", " auto; */\n  padding: 0 ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  \n  /* left: 50%; */\n  /* transform: translateX(-50%); */\n\n  border-radius: 0 0 ", " ", ";\n  z-index: 3;\n\n  img {\n    height: ", ";\n    aspect-ratio: 3/1;\n    cursor: pointer;\n  }\n\n\n  @media (max-width: 650px) {\n    position: relative;\n    background: ", ";\n    height: ", ";\n    margin: 0;\n    padding: ", " ", ";\n\n    img {\n      height: 100%;\n      aspect-ratio: initial;\n    }\n  }\n\n  \n"], ["\n  width: 100%;\n  /* max-width: ", "; */\n  max-width: ", ";\n  /* margin: ", " auto; */\n  padding: 0 ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  \n  /* left: 50%; */\n  /* transform: translateX(-50%); */\n\n  border-radius: 0 0 ", " ", ";\n  z-index: 3;\n\n  img {\n    height: ", ";\n    aspect-ratio: 3/1;\n    cursor: pointer;\n  }\n\n\n  @media (max-width: 650px) {\n    position: relative;\n    background: ", ";\n    height: ", ";\n    margin: 0;\n    padding: ", " ", ";\n\n    img {\n      height: 100%;\n      aspect-ratio: initial;\n    }\n  }\n\n  \n"])), (0, polished_1.rem)(1622), (0, polished_1.rem)(1498), (0, polished_1.rem)(25), (0, polished_1.rem)(24), (0, polished_1.rem)(24), (0, polished_1.rem)(24), (0, polished_1.rem)(75), function (_a) {
    var theme = _a.theme;
    return theme.colors.white;
}, (0, polished_1.rem)(60), (0, polished_1.rem)(12), (0, polished_1.rem)(24));
exports.ButtonMenu = styled_components_1.default.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-content: center;\n  justify-content: center;\n\n  background: none;\n  border: none;\n"], ["\n  display: flex;\n  align-content: center;\n  justify-content: center;\n\n  background: none;\n  border: none;\n"])));
exports.MenuContainer = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: fixed;\n  background: ", ";\n\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: 0;\n  z-index: 2;\n\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n  padding: ", " ", " ", ";\n  gap: ", ";\n\n  ", "\n\n  a {\n    position: relative;\n    font-size: ", ";\n    font-weight: 500;\n    text-decoration: none;\n    color: ", ";\n    cursor: pointer;\n  }\n\n  a.has-group {\n    > svg {\n      transform: rotate(90deg);\n      transition: all 0.2s ease-in-out;\n\n      &.open {\n        transform: rotate(270deg);\n      }\n    }\n  }\n"], ["\n  position: fixed;\n  background: ", ";\n\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: 0;\n  z-index: 2;\n\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n  padding: ", " ", " ", ";\n  gap: ", ";\n\n  ", "\n\n  a {\n    position: relative;\n    font-size: ", ";\n    font-weight: 500;\n    text-decoration: none;\n    color: ", ";\n    cursor: pointer;\n  }\n\n  a.has-group {\n    > svg {\n      transform: rotate(90deg);\n      transition: all 0.2s ease-in-out;\n\n      &.open {\n        transform: rotate(270deg);\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.white;
}, (0, polished_1.rem)(120), (0, polished_1.rem)(24), (0, polished_1.rem)(24), (0, polished_1.rem)(20), function (_a) {
    var isMobile = _a.isMobile;
    return isMobile && (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    gap: ", ";\n  "], ["\n    gap: ", ";\n  "])), (0, polished_1.rem)(50));
}, (0, polished_1.rem)(20), function (_a) {
    var theme = _a.theme;
    return theme.colors.gray[400];
});
exports.Group = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
exports.GroupItem = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n\n  p {\n    font-weight: normal;\n    color: ", ";\n    margin-top: ", ";\n    font-size: ", ";\n  }\n\n  ul {\n    li {\n      list-style: none;\n      color: ", ";\n      cursor: auto;\n\n      & + li {\n        margin-top: ", ";\n      }\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n\n  p {\n    font-weight: normal;\n    color: ", ";\n    margin-top: ", ";\n    font-size: ", ";\n  }\n\n  ul {\n    li {\n      list-style: none;\n      color: ", ";\n      cursor: auto;\n\n      & + li {\n        margin-top: ", ";\n      }\n    }\n  }\n"])), (0, polished_1.rem)(12), function (_a) {
    var theme = _a.theme;
    return (0, polished_1.opacify)(-0.4, theme.colors.blue[900]);
}, (0, polished_1.rem)(20), (0, polished_1.rem)(16), function (_a) {
    var theme = _a.theme;
    return theme.colors.blue[900];
}, (0, polished_1.rem)(10));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
